import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { history } from "../_helpers";
import PublicPages from "../layouts/Pages";

import { alertActions, userActions } from "_actions";

import AuthenticatedFrame from "./AuthenticatedFrame";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  publicRoutes = (
    <React.Fragment>
      {alert.message && <div>{alert.message.message}</div>}
      <Router history={history}>
        <Switch>
          <Route path="/login" component={PublicPages} />
          <Route path="/register" component={PublicPages} />
          <Route path="/terms" component={PublicPages} />
          <Route path="/verified" component={PublicPages} />
          <Route path="/verify" component={PublicPages} />
          <Redirect from="/" to="/login" />
        </Switch>
      </Router>{" "}
    </React.Fragment>
  );

  render() {
    const user = localStorage.getItem("user");
    if (user) {
      return <AuthenticatedFrame />;
    } else return this.publicRoutes;
  }
}

App.propTypes = {
  clearAlerts: PropTypes.func.isRequired,
  verifyToken: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { alert, users } = state;
  return {
    alert,
    users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearAlerts: () => {
      dispatch(alertActions.clear());
    },
    verifyToken: () => {
      dispatch(userActions.verifyToken());
    }
  };
}

const connectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export { connectedApp as App };
