import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { alertActions } from "_actions";

class Notifier extends React.Component {
  state = {
    open: false,
    message: ""
  };

  // to stop the warning of calling setState of unmounted component
  componentWillUnmount() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  componentDidMount() {
    // console.log("Notifer:", this.props.message);
    this.setState({
      open: this.props.open,
      message: this.props.message
    });
    setTimeout(
      function() {
        this.handleSnackbarClose();
      }.bind(this),
      6000
    );
  }

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: ""
    });
    const { dispatch } = this.props;
    dispatch(alertActions.clear());
  };

  render() {
    const message = (
      <span
        id="snackbar-message-id"
        dangerouslySetInnerHTML={{ __html: this.state.message }}
      />
    );

    return (
      <Snackbar
        place="bc"
        color="warning"
        icon={AddAlert}
        message={message}
        closeNotification={this.handleSnackbarClose}
        open={this.state.open}
        close
      />
    );
  }
}

Notifier.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedNotifier = connect(mapStateToProps)(Notifier);
export default connectedNotifier;
