import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import pricingPageStyle from "../../assets/jss/material-dashboard-pro-react/views/pricingPageStyle.jsx";
import { organizationName } from "common/globalConstants";

import Terms from "./Terms";

class TermsAndConditions extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <h3 className={classes.title}>
              {organizationName} - Terms and Conditions
            </h3>
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card terms and conditions>
              <CardBody contract>
                <Terms />
                <Button round color="black" onClick={this.props.history.goBack}>
                  Back
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pricingPageStyle)(TermsAndConditions);
