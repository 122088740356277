import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import genericPageStyle from "../../assets/jss/material-dashboard-pro-react/views/genericPageStyle.jsx";

class Verified extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Welcome!</h2>
              <CardBody>
                <GridContainer justify="center">
                  <h4>It's great to have you on board.</h4>
                  <h4>
                    We have sent you a confirmation email,{" "}
                    <b>Please check your mailbox</b> and follow the email
                    instructions.
                  </h4>
                </GridContainer>
                <div className={classes.center}>
                  <br />
                  <Button
                    round
                    onClick={this.props.history.goBack}
                    color="primary"
                  >
                    Back
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Verified.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(genericPageStyle)(Verified);
