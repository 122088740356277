import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import genericPageStyle from "../../assets/jss/material-dashboard-pro-react/views/genericPageStyle.jsx";

class Verified extends React.Component {
  handleClick = () => {
    this.props.history.push("/login");
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>Welcome on board!</h2>
              <CardBody>
                <GridContainer justify="center">
                  <h4>Your email is now verified, you are welcome to login.</h4>
                </GridContainer>
                <div className={classes.center}>
                  <br />
                  <Button round onClick={this.handleClick} color="primary">
                    Take me in
                  </Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Verified.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(genericPageStyle)(Verified);
