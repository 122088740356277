import React from "react";

// @material-ui/core components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

// core components
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

import { defaultFont } from "assets/jss/material-dashboard-pro-react.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";

const classes = theme => ({
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: "#3C4858",
    fontSize: "18px"
  },
  cardHeader: {
    zIndex: "3"
  },
  cardSubtitle: {
    ...defaultFont,
    color: "#999999",
    fontSize: "14px",
    margin: "0 0 10px"
  },
  center: {
    textAlign: "center"
  },
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  marginRight: {
    marginRight: "5px"
  },
  modalSectionTitle: {
    marginTop: "30px"
  },
  ...modalStyle(theme)
});

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const SimpleModal = props => {
  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal + " " + classes.modalSmall
      }}
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="small-modal-slide-title"
      aria-describedby="small-modal-slide-description"
    >
      <DialogTitle
        id="small-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.handleClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="small-modal-slide-description"
        className={classes.modalBody + " " + classes.modalSmallBody}
      >
        <h5>{props.question}</h5>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button
          onClick={props.handleClose}
          color="transparent"
          className={classes.modalSmallFooterFirstButton}
        >
          Never Mind
        </Button>
        <Button
          onClick={props.handleConfirm}
          color="success"
          simple
          className={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }
        >
          {props.confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleModal;
