import React from "react";
import { connect } from "react-redux";

import { userActions } from "_actions";

import NewUserForm from "./NewUserForm.jsx";
import Notifier from "customComponents/Notifier/Notifier.jsx";

class NewUser extends React.Component {
  submit = values => {
    const { name, email, password, group } = values;
    const { dispatch } = this.props;

    dispatch(userActions.create(name, email, password, group));
  };

  render() {
    const { alert } = this.props;

    return (
      <React.Fragment>
        <NewUserForm onSubmit={this.submit} />;
        {alert.message && <Notifier open={true} message={alert.message} />}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedAdminPage = connect(mapStateToProps)(NewUser);
export default connectedAdminPage;
