import Joi from "joi-browser";

const validate = (values, schema) => {
  const options = { abortEarly: false };
  const { error } = Joi.validate(values, schema, options);

  if (!error) return {};

  const errors = {};
  for (let item of error.details) errors[item.path[0]] = item.message;
  return errors;
};

export default validate;
