import axios from "axios";

import { authHeader } from "../_helpers";
import { userService } from "./user.service";

export const pbiService = {
  listReports,
  listGroups,
  setPages
};

async function setPages(reportId, pages) {
  const { user } = await userService.get();

  if (user.organizationId) {
    const groupId = user.organizationId;

    const url = `/api/bpiHelpers/listGroupReports?groupId=${groupId}`;

    try {
      const res = await axios.get(url, { headers: authHeader() });
      const list = res.data.reportsList;

      const list2 = list.map((prop, key) => {
        return {
          ...prop,
          collapse: true,
          path: `/${prop.name.replace(/\s+/g, "")}`,
          views: []
        };
      });

      return list2;
    } catch (err) {
      console.error("listReports:", err);
      throw err;
    }
  }
  return [];
}

async function listReports() {
  const { user } = await userService.get();

  if (user.organizationId) {
    const groupId = user.organizationId;

    const url = `/api/bpiHelpers/listGroupReports?groupId=${groupId}`;

    try {
      const res = await axios.get(url, { headers: authHeader() });
      const list = res.data.reportsList;

      const list2 = list.map((prop, key) => {
        return {
          ...prop,
          collapse: true,
          path: `/${prop.name.replace(/\s+/g, "")}`,
          views: []
        };
      });

      return list2;
    } catch (err) {
      console.error("listReports:", err);
      throw err;
    }
  }
  return [];
}

async function listGroups() {
  const url = `/api/bpiHelpers/listGroups`;

  try {
    const res = await axios.get(url, { headers: authHeader() });
    const list = res.data.groupsList;
    return list;
  } catch (err) {
    console.error("listGroups:", err);
    throw err;
  }
}
