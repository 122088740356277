import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { history } from "../_helpers";

import UsersFrame from "./UsersFrame";
import AdminFrame from "./AdminFrame";

import { userActions, pbiActions, alertActions } from "_actions";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

class AuthenticatedFrame extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  componentDidMount() {
    // Here we do all aSync calls on application loading phase.
    this.props.getUser();
    this.props.verifyToken();
    this.props.listReports();
  }

  getFrame(users) {
    //if (users.roles) {
    if (users.roles.length > 0) {
      if (users.roles[0].name === "admin") return <AdminFrame />;
    }
    //}
    return <UsersFrame />;
  }

  render() {
    // const { users, reports, alert } = this.props;
    const { users } = this.props;

    return users.loading && users.verifyingToken ? null : this.getFrame(users);
  }
}

AuthenticatedFrame.propTypes = {
  listReports: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  verifyToken: PropTypes.func.isRequired,
  clearAlerts: PropTypes.func.isRequired,
  cookies: PropTypes.object
};

function mapStateToProps(state) {
  const { alert, pbi, users } = state;
  return {
    alert,
    pbi,
    users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listReports: () => {
      dispatch(pbiActions.listReports());
    },
    getUser: () => {
      dispatch(userActions.get());
    },
    verifyToken: () => {
      dispatch(userActions.verifyToken());
    },
    clearAlerts: () => {
      dispatch(alertActions.clear());
    }
  };
}

const connectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedFrame);

export default connectedApp;
