import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";

import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx";

function CustomInput({ ...props }) {
  const {
    input,
    classes,
    formControlProps,
    labelText,
    labelProps,
    inputProps,
    white,
    inputRootCustomClasses,
    helpText,
    showValidateIcons,
    startIcon,
    endIcon,
    placeHolder,
    type,
    meta
  } = props;

  const error = meta.error && meta.touched ? true : false;
  const success = !meta.error && meta.touched ? true : false;

  const validateIcon =
    showValidateIcons && meta.error && meta.touched
      ? {
          endAdornment: (
            <InputAdornment position="end" className={classes.inputAdornment}>
              <Clear className={classes.inputAdornmentIconError} />
            </InputAdornment>
          )
        }
      : showValidateIcons && !meta.error && meta.touched
        ? {
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <Check className={classes.inputAdornmentIconSuccess} />
              </InputAdornment>
            )
          }
        : null;

  const endInputIcon = endIcon
    ? {
        endAdornment: (
          <InputAdornment position="end" className={classes.inputAdornment}>
            {React.createElement(endIcon, {
              className: classes.inputAdornmentIcon
            })}
          </InputAdornment>
        )
      }
    : null;

  const startInputIcon = startIcon
    ? {
        startAdornment: (
          <InputAdornment position="start" className={classes.inputAdornment}>
            {React.createElement(startIcon, {
              className: classes.inputAdornmentIcon
            })}
          </InputAdornment>
        )
      }
    : null;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });
  return (
    <FormControl {...formControlProps} className={formControlClasses} fullWidth>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}

      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        type={type}
        placeholder={placeHolder}
        {...inputProps}
        {...validateIcon}
        {...endInputIcon}
        {...startInputIcon}
        {...input}
      />
      {meta.error && meta.touched ? (
        <FormHelperText className={helpTextClasses}>
          {meta.error}
        </FormHelperText>
      ) : null}

      {helpText !== undefined ? (
        <FormHelperText className={helpTextClasses}>{helpText}</FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node
};

export default withStyles(customInputStyle)(CustomInput);
