import axios from "axios";
import { authHeader } from "../_helpers";

export async function getReportEmbedToken(groupId, reportId) {
  const url = `/api/bpiHelpers/getEmbedToken?groupId=${groupId}&reportId=${reportId}`;

  try {
    const res = await axios.get(url, { headers: authHeader() });
    const embedToken = res.data.embedToken;
    return embedToken;
  } catch (err) {
    console.error("getReportEmbedToken:", err);
    return null;
  }
}
