import Pages from "layouts/Pages.jsx";
import Admin from "layouts/Admin.jsx";

var indexRoutes = [
  { path: "/pages", name: "Pages", component: Pages },
  { path: "/admin", name: "Admin", component: Admin },
  { path: "/", name: "Admin", component: Admin }
];

export default indexRoutes;
