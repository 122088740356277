import { authHeader } from "../_helpers";
import axios from "axios";

export const userService = {
  login,
  verifyToken,
  logout,
  create,
  get,
  set
};

async function create(name, email, password, organizationId, emailVerified) {
  const options = { headers: authHeader() };

  const data = {
    name: name,
    email: email.toLowerCase(),
    password,
    organizationId: organizationId,
    emailVerified: emailVerified
  };

  const url = `/api/Users`;

  try {
    const res = await axios.post(url, data, options);
    const user = res.data;
    return user;
  } catch (err) {
    console.error("User Create:", err);
    throw err;
  }
}

async function set(id, name, organization, password) {
  const options = { headers: authHeader() };

  var data = {
    name: name,
    organizationId: organization
  };

  if (password) data = { ...data, password: password };

  const url = `/api/Users/${id}`;

  try {
    const res = await axios.patch(url, data, options);
    const user = res.data;
    return user;
  } catch (err) {
    console.error("User Set:", err);
    throw err;
  }
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: username.toLowerCase(), password })
  };

  return fetch(`/api/Users/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a loopback token in the response
      if (user.id) {
        // store user details and token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

async function verifyToken() {
  console.log("Verifying Token...");
  const user = localStorage.getItem("user");
  if (user) {
    const userObj = JSON.parse(user);
    const uid = userObj.userId;
    const token = userObj.id;
    const url = `/api/users/${uid}/accessTokens/${token}`;

    try {
      const res = await axios.get(url, { headers: authHeader() });
      const token = res.data;

      const created = Date.parse(token.created);
      const { ttl } = token;

      const expire = created + ttl;
      const now = new Date().getTime();

      //console.log(expire, now);

      if (expire > now) {
        console.log("Token is valid");
        return true;
      }
    } catch (err) {
      console.error("verifyToken:", err);
      if (err.response.status === 401) {
        console.log("You are an unauthenticated user, removing your token.");
        localStorage.removeItem("user");
      }
      throw err;
    }
  }
  console.log("Token is expired, removing token from browser localStorage");
  localStorage.removeItem("user");
  return false;
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function get() {
  const requestOptions = {
    method: "GET",
    headers: authHeader()
  };

  return fetch(`/api/Users/getByToken`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  var error;
  if (!response.ok) {
    switch (response.status) {
      case 401:
        //logout();
        error = "Wrong email or password";
        break;
      case 422:
        error = "User already exist";
        break;
      default:
        error = "Internal server error, please try again later."; // (data && data.message) || response.statusText;
    }

    return Promise.reject(error);
  }

  return response.json();
}
