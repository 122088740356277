import { pbiConstants } from "../_constants";
import { pbiService } from "../_services";
import { alertActions } from ".";

export const pbiActions = {
  listReports,
  listGroups,
  setPages
};

function setPages(reportId, pages) {
  return dispatch => {
    dispatch(set(reportId, pages));
  };

  function set(reportId, pages) {
    console.log("inActions");
    return {
      type: pbiConstants.SETPAGES,
      reportId,
      pages
    };
  }
}

function listReports() {
  return dispatch => {
    dispatch(request());

    pbiService.listReports().then(
      reportsList => {
        dispatch(success(reportsList));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(reports) {
    return {
      type: pbiConstants.REPORTSLIST_REQUEST,
      reports
    };
  }
  function success(reports) {
    return {
      type: pbiConstants.REPORTSLIST_SUCCESS,
      reports
    };
  }
  function failure(error) {
    return { type: pbiConstants.REPORTSLIST_FAILURE, error };
  }
}

function listGroups() {
  return dispatch => {
    dispatch(request());

    pbiService.listGroups().then(
      groupsList => {
        dispatch(success(groupsList));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(groups) {
    return {
      type: pbiConstants.GROUPSLIST_REQUEST,
      groups
    };
  }
  function success(groups) {
    return {
      type: pbiConstants.GROUPSLIST_SUCCESS,
      groups
    };
  }
  function failure(error) {
    return { type: pbiConstants.GROUPSLIST_FAILURE, error };
  }
}
