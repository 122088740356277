import axios from "axios";
import { authHeader } from "../_helpers";

export async function getUsers() {
  const url = `/api/users?filter={"include": "roles"}`;

  try {
    const res = await axios.get(url, { headers: authHeader() });
    const usersList = res.data;
    return usersList;
  } catch (err) {
    console.error("getUsers:", err);
    return null;
  }
}

export async function deleteUser(id) {
  const url = `/api/users/${id}`;

  try {
    const res = await axios.delete(url, { headers: authHeader() });
    const numDeleted = res.data.count;
    return numDeleted;
  } catch (err) {
    console.error("deleteUser:", err);
    return null;
  }
}
