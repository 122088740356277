import Welcome from "views/Welcome/Welcome.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

var dashRoutes = [
  {
    path: "/welcome",
    name: "Welcome",
    icon: DashboardIcon,
    component: Welcome,
    hidden: false
  },
  // {
  //   path: "/CreateShop",
  //   name: "CreateShop",
  //   icon: LocalShipping,
  //   component: CreateShop,
  //   hidden: false
  // },

  { redirect: true, path: "/", pathTo: "/welcome", name: "Welcome" }
];
export default dashRoutes;
