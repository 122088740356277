import LoginPage from "views/Pages/LoginPage/LoginPage.jsx";
import TermsAndConditions from "../views/Pages/TermsAndConditions.jsx";

// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";
import Notes from "@material-ui/icons/Notes";

const pagesRoutes = [
  // {
  //   path: "/register",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   icon: PersonAdd,
  //   component: RegisterPage
  // },
  {
    path: "/login",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: "/terms",
    name: "Terms And Conditions",
    short: "Terms",
    mini: "T&C",
    icon: Notes,
    component: TermsAndConditions
  }
];

export default pagesRoutes;
