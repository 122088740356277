import Joi from "joi-browser";
import formValidation from "common/formValidation.js";

const schema = {
  name: Joi.string()
    .required()
    .label("Name"),
  email: Joi.string()
    .required()
    .label("Email"),
  password: Joi.string()
    .required()
    .label("Password"),
  group: Joi.string()
    .required()
    .label("Group")
};

function validate(values) {
  return formValidation(values, schema);
}

export default validate;
