import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import bigLogo from "assets/img/bigLogo.png";
import { organizationShortName } from "common/globalConstants";

class Welcome extends React.Component {
  render() {
    return (
      <div>
        <h1>Welcome to {organizationShortName}</h1>
        <p>Use the left navigation bar to browse between the reports.</p>
        <div>
          <img
            src={bigLogo}
            alt={organizationShortName + " Logo"}
            style={{ width: "70%", height: "auto" }}
          />
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Welcome);
