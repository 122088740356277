export const pbiConstants = {
  REPORTSLIST_REQUEST: "REPORTS_LIST_REQUEST",
  REPORTSLIST_SUCCESS: "REPORTS_LIST_SUCCESS",
  REPORTSLIST_FAILURE: "REPORTS_LIST_FAILURE",

  GROUPSLIST_REQUEST: "GROUPS_LIST_REQUEST",
  GROUPSLIST_SUCCESS: "GROUPS_LIST_SUCCESS",
  GROUPSLIST_FAILURE: "GROUPS_LIST_FAILURE",

  SETPAGES: "SET_PAGES"
};
