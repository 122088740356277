import ManageUsers from "views/ManageUsers/ManageUsers.jsx";
import NewUser from "views/NewUser/NewUser.jsx";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";
import GroupAdd from "@material-ui/icons/GroupAdd";

var dashRoutes = [
  {
    path: "/users",
    name: "Manage Users",
    icon: Settings,
    component: ManageUsers,
    hidden: false
  },
  {
    path: "/newuser",
    name: "New User",
    icon: GroupAdd,
    component: NewUser,
    hidden: false
  },

  { redirect: true, path: "/", pathTo: "/users", name: "Manage Users" }
];
export default dashRoutes;
