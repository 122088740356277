export const userConstants = {
  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  VERIFYTOKEN_REQUEST: "USER_VERIFYTOKEN_REQUEST",
  VERIFYTOKEN_SUCCESS: "USER_VERIFYTOKEN_SUCCESS",
  VERIFYTOKEN_FAILURE: "USER_VERIFYTOKEN_FAILURE",

  LOGOUT: "USER_LOGOUT",

  CREATE_REQUEST: "USER_CREATE_REQUEST",
  CREATE_SUCCESS: "USER_CREATE_SUCCESS",
  CREATE_FAILURE: "USER_CREATE_FAILURE",

  GET_REQUEST: "USER_GET_REQUEST",
  GET_SUCCESS: "USER_GET_SUCCESS",
  GET_FAILURE: "USER_GET_FAILURE",

  SET_REQUEST: "USER_SET_REQUEST",
  SET_SUCCESS: "USER_SET_SUCCESS",
  SET_FAILURE: "USER_SET_FAILURE"
};
