import { pbiConstants } from "../_constants";

const startingState = {
  reports: [],
  groups: [],
  loading: null
};

function getAcronyms(str) {
  const words = str.split(" ");
  var acronyms;
  if (words.length > 1) {
    if (words[1][0] === "-") acronyms = words[0][0] + words[2][0];
    else acronyms = words[0][0] + words[1][0];
  } else acronyms = str[0] + str[1];
  return acronyms.toUpperCase();
}

function addPages(state, action) {
  const pagesList = action.pages
    .filter(page => page.visibility === 0)
    .map(page => {
      return {
        ...page,
        manipulatedName:
          page.displayName.length > 25
            ? page.displayName.substring(0, 24) + "..."
            : page.displayName,
        path: "123",
        mini: getAcronyms(page.displayName)
      };
    });

  const newState = state.reports.map(report => {
    if (report.id === action.reportId) return { ...report, views: pagesList };
    else return { ...report };
  });
  return newState;
}

export function pbi(state = startingState, action) {
  switch (action.type) {
    // List Reports
    case pbiConstants.REPORTSLIST_REQUEST:
      return {
        ...state,
        reports: [],
        loading: true
      };
    case pbiConstants.REPORTSLIST_SUCCESS:
      return {
        ...state,
        reports: [...action.reports],
        loading: false
      };
    case pbiConstants.REPORTSLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    // List Groups
    case pbiConstants.GROUPSLIST_REQUEST:
      return {
        ...state,
        groups: [],
        loading: true
      };
    case pbiConstants.GROUPSLIST_SUCCESS:
      return {
        ...state,
        groups: [...action.groups],
        loading: false
      };
    case pbiConstants.GROUPSLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    // Set Pages
    case pbiConstants.SETPAGES:
      console.log("REDUCER:", state, action);
      return {
        reports: [...addPages(state, action)],
        loading: false
      };

    default:
      return state;
  }
}
