import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";

export const userActions = {
  login,
  verifyToken,
  logout,
  create,
  get,
  set
};

function set(id, name, organization, password) {
  return dispatch => {
    dispatch(request());

    userService.set(id, name, organization, password).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.SET_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SET_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SET_FAILURE, error };
  }
}

function get() {
  return dispatch => {
    dispatch(request());

    userService.get().then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.GET_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.GET_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.GET_FAILURE, error };
  }
}

function create(name, email, password, organizationId = null, emailVerified = false) {
  return dispatch => {
    dispatch(request({ name }));

    userService.create(name, email, password, organizationId, emailVerified).then(
      user => {
        dispatch(success(user));
        history.push("/verify");
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.CREATE_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.CREATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CREATE_FAILURE, error };
  }
}

function login(username, password) {
  return dispatch => {
    dispatch(request({ username }));

    userService.login(username, password).then(
      user => {
        dispatch(success(user));
        history.push("/");
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function verifyToken() {
  return dispatch => {
    dispatch(request());

    userService.verifyToken().then(
      token => {
        dispatch(success());
        //history.push("/");
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: userConstants.VERIFYTOKEN_REQUEST };
  }
  function success() {
    return { type: userConstants.VERIFYTOKEN_SUCCESS };
  }
  function failure(error) {
    return { type: userConstants.VERIFYTOKEN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}
