import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";

// material-ui icons
import Assignment from "@material-ui/icons/AssignmentInd";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CustomInput from "customComponents/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import { pbiActions } from "_actions";

import DropDown from "customComponents/DropDown/DropDown.jsx";

import validate from "./validate.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class NewUserForm extends React.Component {
  async componentDidMount() {
    this.props.listGroups();
  }

  render() {
    const { classes, pbi, handleSubmit } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <form onSubmit={handleSubmit}>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>New User</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Field
                      name="name"
                      type="test"
                      component={CustomInput}
                      labelText="Name"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Field
                      name="email"
                      type="text"
                      component={CustomInput}
                      labelText="Email"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Field
                      name="password"
                      type="password"
                      component={CustomInput}
                      labelText="Password"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Field
                      name="group"
                      type="text"
                      component={DropDown}
                      labelText="Group"
                      listItems={pbi.groups.map(prop => ({
                        displayName: prop.name,
                        value: prop.id
                      }))}
                      disabledFirstItem="Group"
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button type="submit" color="rose" size="lg">
                  {"   Create   "}
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

NewUserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  listGroups: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func,
  pbi: PropTypes.object.isRequired
};

NewUserForm.defaultProps = {
  pbi: {
    groups: []
  }
};

function mapStateToProps(state) {
  const { pbi } = state;
  return {
    pbi
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listGroups: () => {
      dispatch(pbiActions.listGroups());
    }
  };
}

const connectedNewUserForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUserForm);

const ws = withStyles(styles)(connectedNewUserForm);

export default reduxForm({
  form: "addEditUser",
  validate
})(ws);
