import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";

import reportStyle from "assets/jss/material-dashboard-pro-react/views/reportStyle";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { getReportEmbedToken } from "common/pbiHelper";

import { pbiActions } from "_actions";

class Report extends React.Component {
  constructor(props) {
    super(props);

    this.reportId = props.reportId;
    this.groupId = props.groupId;
    this.embedUrl = props.embedUrl;

    this.state = {
      reportHeight: window.innerHeight - 150
    };

    this.resizeFunction = this.resizeFunction.bind(this);
    this.onReportLoaded = this.onReportLoaded.bind(this);
  }

  resizeFunction() {
    this.setState({ reportHeight: window.innerHeight - 150 });
  }

  async onReportLoaded() {
    console.log("onReportLoaded");

    const reportContainer = window.jQuery("#reportContainer")[0];
    const report = window.powerbi.get(reportContainer);
    const reportId = report.getId();
    const pagesList = await report.getPages();

    this.props.setPages(reportId, pagesList);
  }

  async componentDidMount() {
    const embedToken = await getReportEmbedToken(this.groupId, this.reportId);

    const models = window["powerbi-client"].models;

    const permissions = models.Permissions.All;

    const config = {
      type: "report",
      tokenType: models.TokenType.Embed,
      accessToken: embedToken,
      embedUrl: this.embedUrl,
      id: this.reportId,
      permissions: permissions
    };

    const configMobile = {
      ...config,
      settings: {
        layoutType: models.LayoutType.MobilePortrait,
        filterPaneEnabled: false,
        navContentPaneEnabled: false
      }
    };

    const configNonMobile = {
      ...config,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false
      }
    };

    const reportContainer = window.jQuery("#reportContainer")[0];
    const report = window.powerbi.embed(reportContainer, configNonMobile);
    report.off("loaded");

    report.on("loaded", () => this.onReportLoaded());
    //   report
    //     .getPages()
    //     .then(function(pages) {
    //       var pagesTBL = "";
    //       pages.forEach(function(page) {
    //         pagesTBL += "\n" + page.name + " - " + page.displayName;
    //       });
    //       console.log("Pages:", pagesTBL);
    //       that.onReportLoaded();
    //     })
    //     .catch(function(error) {
    //       console.log("OOPS:", error);
    //     });
    // });

    report.on("error", function(event) {
      console.log(event.detail);
      report.off("error");
    });

    const reportContainerMobile = window.jQuery("#reportContainerMobile")[0];
    window.powerbi.embed(reportContainerMobile, configMobile);

    window.addEventListener("resize", this.resizeFunction);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  render() {
    //const { classes } = this.props;

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <Hidden only={["xs", "sm"]} implementation="css">
              <div
                id="reportContainer"
                style={{
                  height: this.state.reportHeight,
                  minHeight: "100%",
                  boxSizing: "border-box",
                  display: "block"
                }}
              />
            </Hidden>
            <Hidden only={["md", "lg", "xl"]} implementation="css">
              <div
                id="reportContainerMobile"
                style={{
                  height: this.state.reportHeight,
                  minHeight: "100%",
                  boxSizing: "border-box",
                  display: "block"
                }}
              />
            </Hidden>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

Report.propTypes = {
  classes: PropTypes.object.isRequired,
  reportId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  embedUrl: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  const { pbi } = state;
  return {
    pbi
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPages: (reportId, Pages) => {
      dispatch(pbiActions.setPages(reportId, Pages));
    }
  };
}

const ws = withStyles(reportStyle)(Report);

const connectedReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(ws);

export default connectedReport;
