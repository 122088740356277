import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
// import { createLogger } from "redux-logger";
import rootReducer from "../_reducers";

// const loggerMiddleware = createLogger();

export const store =
  process.env.NODE_ENV !== "production" && window.devToolsExtension
    ? createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(thunkMiddleware))
      )
    : createStore(rootReducer, compose(applyMiddleware(thunkMiddleware)));
