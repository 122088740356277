import React from "react";
import { connect } from "react-redux";

import { userActions } from "_actions";

import Notifier from "customComponents/Notifier/Notifier.jsx";

import ManageUsersForm from "./ManageUsersForm.jsx";
import SimpleModal from "./SimpleModal";

class ManageUsers extends React.Component {
  state = {
    resetPasswordModal: false,
    selectedUserId: null,
    selectedUserName: null,
    selectedUserGroup: null,
    selectedUserPassword: null
  };

  submit = values => {
    const { id, name, group, password } = values;
    const { dispatch } = this.props;

    if (password)
      this.setState({
        resetPasswordModal: true,
        selectedUserId: id,
        selectedUserName: name,
        selectedUserGroup: group,
        selectedUserPassword: password
      });
    else dispatch(userActions.set(id, name, group));
  };

  handleCloseResetPassword = action => {
    const {
      selectedUserId,
      selectedUserName,
      selectedUserGroup,
      selectedUserPassword
    } = this.state;
    const { dispatch } = this.props;

    if (action === "confirm") {
      dispatch(
        userActions.set(
          selectedUserId,
          selectedUserName,
          selectedUserGroup,
          selectedUserPassword
        )
      );
    }
    this.setState({
      resetPasswordModal: false,
      selectedUserId: null,
      selectedUserName: null,
      selectedUserGroup: null,
      selectedUserPassword: null
    });
  };

  render() {
    const { alert } = this.props;

    return (
      <React.Fragment>
        <ManageUsersForm onSubmit={this.submit} />;
        <SimpleModal
          open={this.state.resetPasswordModal}
          question={`This will change the user's password.`}
          handleClose={() => this.handleCloseResetPassword()}
          handleConfirm={() => this.handleCloseResetPassword("confirm")}
          confirmLabel="Ok"
        />
        {alert.message && <Notifier open={true} message={alert.message} />}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedAdminPage = connect(mapStateToProps)(ManageUsers);
export default connectedAdminPage;
