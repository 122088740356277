import React from "react";
import { connect } from "react-redux";

import { userActions } from "_actions";

import LoginForm from "./LoginForm.jsx";
import Notifier from "customComponents/Notifier/Notifier.jsx";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
  }

  submit = values => {
    const { email, password } = values;
    const { dispatch } = this.props;

    dispatch(userActions.login(email, password));
  };

  render() {
    const { alert } = this.props;

    return (
      <React.Fragment>
        <LoginForm onSubmit={this.submit} />;
        {alert.message && <Notifier open={true} message={alert.message} />}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert } = state;
  return {
    loggingIn,
    alert
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export default connectedLoginPage;
