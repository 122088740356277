import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import customInputStyle from "assets/jss/material-dashboard-pro-react/customComponents/DropDownStyle.jsx";

function DropDown({ ...props }) {
  const {
    input,
    classes,
    labelText,
    id,
    inputProps,
    meta,
    disabledFirstItem,
    listItems
  } = props;

  const error = meta.error && meta.touched ? true : false;
  const success = !meta.error && meta.touched ? true : false;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });

  const menuItemClasses = {
    root: classes.selectMenuItem,
    selected: classes.selectMenuItemSelected
  };

  const renderListItems = listItems
    ? listItems.map(item => (
        <MenuItem classes={menuItemClasses} value={item.value} key={item.value}>
          {item.displayName}
        </MenuItem>
      ))
    : null;

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      {labelText ? (
        <InputLabel
          htmlFor="simple-select"
          className={classes.labelRoot + " " + labelClasses}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        inputProps={inputProps}
        {...input}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
        >
          {disabledFirstItem}
        </MenuItem>
        {renderListItems}
      </Select>

      {meta.error && meta.touched ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {meta.error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

DropDown.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helpText: PropTypes.node
};

export default withStyles(customInputStyle)(DropDown);
