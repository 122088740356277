import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { history } from "../_helpers";

import indexAdminRouts from "../routes/indexAdmin.jsx";

import { alertActions } from "_actions";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

class AdminFrame extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change
      this.props.clearAlerts();
    });
  }

  indexAdminRouts = (
    <React.Fragment>
      {alert.message && <div>{alert.message.message}</div>}
      <Router history={history}>
        <Switch>
          {indexAdminRouts.map((prop, key) => {
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </Router>
    </React.Fragment>
  );

  render() {
    // const { users, reports, alert } = this.props;
    return this.indexAdminRouts;
  }
}

AdminFrame.propTypes = {
  clearAlerts: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearAlerts: () => {
      dispatch(alertActions.clear());
    }
  };
}

const connectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminFrame);

export default connectedApp;
