import { userConstants } from "../_constants";

const startingState = {
  roles: [],
  loading: null,
  verifyingToken: null,
  tokenVerified: false
};

export function users(state = startingState, action) {
  switch (action.type) {
    case userConstants.VERIFYTOKEN_SUCCESS:
      return {
        ...state,
        verifyingToken: false,
        tokenVerified: true
      };
    case userConstants.VERIFYTOKEN_REQUEST:
      return {
        ...state,
        verifyingToken: true
      };
    case userConstants.VERIFYTOKEN_FAILURE:
      return {
        ...state,
        verifyingToken: true,
        tokenVerified: true
      };
    case userConstants.GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.user.user
      };
    case userConstants.GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SET_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.SET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
}
