import Joi from "joi-browser";
import formValidation from "common/formValidation.js";

const schema = {
  id: Joi.string()
    .required()
    .label("Id"),
  name: Joi.string()
    .required()
    .label("Name"),
  // email: Joi.string()
  //   .required()
  //   .label("Email"),
  group: Joi.string()
    .required()
    .label("Group"),
  password: Joi.string().label("Password")
};

function validate(values) {
  return formValidation(values, schema);
}

export default validate;
