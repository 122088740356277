import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";

// material-ui icons
import Assignment from "@material-ui/icons/AssignmentInd";
// import Security from "@material-ui/icons/Security";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import CustomInput from "customComponents/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";

// react table
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import { pbiActions } from "_actions";
import { pbiService } from "_services";

import { getUsers, deleteUser } from "common/adminHelper";

import DropDown from "customComponents/DropDown/DropDown.jsx";

import SimpleModal from "./SimpleModal";
import validate from "./validate.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

//TODO: call Delete user api then get users again
//TODO: call reset password api
//TODO: Create/Edit user componenet

class ManageUsersForm extends React.Component {
  state = {
    usersTable: [],
    deleteModal: false,
    resetPasswordModal: false,
    selectedUserId: null,
    selectedUserName: null,
    selectedUserEmail: null,
    formDisabled: true
  };

  async handleCloseDelete(action) {
    var numDeleted;
    if (action === "confirm") {
      numDeleted = await deleteUser(this.state.selectedUserId);
      console.log(`Deleting ${this.state.selectedUserName}...`);
    }
    this.setState({
      deleteModal: false,
      selectedUserId: null,
      selectedUserEmail: null,
      selectedUserName: null
    });
    if (numDeleted > 0) this.loadUsersTable();
  }

  // handleCloseResetPassword(action) {
  //   if (action === "confirm") {
  //     console.log(
  //       `Sending email with reset passwrod like to ${
  //         this.state.selectedUserName
  //       }...`
  //     );
  //   }
  //   this.setState({
  //     resetPasswordModal: false,
  //     selectedUserEmail: null,
  //     selectedUserName: null
  //   });
  // }

  handleCancelClick() {
    this.setState({ formDisabled: true, selectedUserEmail: null });
    this.props.reset("editUser");
  }

  handleEditClick(id, name, group, email) {
    this.props.change("id", id);
    this.props.change("name", name);
    this.props.change("group", group);
    this.setState({ formDisabled: false, selectedUserEmail: email });
  }

  handleResetPasswordClick(id, name, email) {
    this.setState({
      resetPasswordModal: true,
      selectedUserId: id,
      selectedUserEmail: email,
      selectedUserName: name
    });
  }

  handleDeleteClick(id, name, email) {
    this.setState({
      deleteModal: true,
      selectedUserId: id,
      selectedUserEmail: email,
      selectedUserName: name
    });
  }

  usersTable(users) {
    let table = null;
    if (users.length > 0) {
      table = users.map((prop, key) => {
        return {
          id: prop.id,
          name: prop.name,
          email: prop.email,
          group: prop.groupName ? prop.groupName : null,
          groupId: prop.organizationId ? prop.organizationId : null,
          actions: (
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() =>
                  this.handleEditClick(
                    prop.id,
                    prop.name,
                    prop.organizationId ? prop.organizationId : null,
                    prop.email
                  )
                }
                color="info"
                className="like"
              >
                <Edit />
              </Button>
              {/* <Button
                justIcon
                round
                simple
                onClick={() =>
                  this.handleResetPasswordClick(prop.id, prop.name, prop.email)
                }
                color="warning"
                className="edit"
              >
                <Security />
              </Button> */}
              <Button
                justIcon
                round
                simple
                onClick={() =>
                  this.handleDeleteClick(prop.id, prop.name, prop.email)
                }
                color="danger"
                className="remove"
              >
                <Close />
              </Button>
            </div>
          )
        };
      });
    }
    return table;
  }

  async loadUsersTable() {
    const usersList = await getUsers();
    const groups = await pbiService.listGroups();
    const filteredUsers = usersList.filter(user => user.roles.length === 0);
    filteredUsers.forEach(user => {
      const group = groups.filter(group => group.id === user.organizationId);
      user.groupName = group[0].name;
    });
    if (filteredUsers.length > 0)
      this.setState({ usersTable: this.usersTable(filteredUsers) });
  }

  async componentDidMount() {
    this.props.listGroups();
    this.loadUsersTable();
  }

  render() {
    const { classes, pbi, handleSubmit } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <form onSubmit={handleSubmit}>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Edit User{" "}
                  {this.state.selectedUserEmail
                    ? `(${this.state.selectedUserEmail})`
                    : null}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <Field
                      name="name"
                      type="test"
                      component={CustomInput}
                      labelText="Name"
                      inputProps={{ disabled: this.state.formDisabled }}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={3}>
                    <Field
                      name="email"
                      type="text"
                      component={CustomInput}
                      labelText="Email"
                    />
                  </GridItem>*/}
                  <GridItem xs={12} sm={12} md={4}>
                    <Field
                      name="group"
                      type="text"
                      component={DropDown}
                      labelText="Group"
                      listItems={pbi.groups.map(prop => ({
                        displayName: prop.name,
                        value: prop.id
                      }))}
                      disabledFirstItem="Group"
                      inputProps={{ disabled: this.state.formDisabled }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Field
                      name="password"
                      type="password"
                      component={CustomInput}
                      labelText="Password"
                      inputProps={{ disabled: this.state.formDisabled }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button
                  type="submit"
                  color="rose"
                  size="lg"
                  {...(this.state.formDisabled ? { disabled: true } : null)}
                >
                  {"   Update   "}
                </Button>
                <Button
                  onClick={() => this.handleCancelClick()}
                  color="rose"
                  size="lg"
                  {...(this.state.formDisabled ? { disabled: true } : null)}
                >
                  {"   Cancel   "}
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Users List</h4>
            </CardHeader>

            <CardBody>
              <ReactTable
                data={this.state.usersTable}
                filterable
                columns={[
                  {
                    Header: "ID",
                    accessor: "id",
                    show: false
                  },
                  {
                    Header: "Name",
                    accessor: "name"
                  },
                  {
                    Header: "Email",
                    accessor: "email"
                  },
                  {
                    Header: "Group",
                    accessor: "group"
                  },
                  {
                    Header: "GroupId",
                    accessor: "groupId",
                    show: false
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationBottom={true}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        <SimpleModal
          open={this.state.deleteModal}
          question={`Are you sure you want to delete?`}
          handleClose={() => this.handleCloseDelete()}
          handleConfirm={() => this.handleCloseDelete("confirm")}
          confirmLabel="Yes"
        />
      </GridContainer>
    );
  }
}

ManageUsersForm.propTypes = {
  classes: PropTypes.object.isRequired,
  listGroups: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func,
  pbi: PropTypes.object.isRequired
};

ManageUsersForm.defaultProps = {
  pbi: {
    groups: []
  }
};

function mapStateToProps(state) {
  const { pbi } = state;
  return {
    pbi
  };
}

function mapDispatchToProps(dispatch) {
  return {
    listGroups: () => {
      dispatch(pbiActions.listGroups());
    }
  };
}

const connectedManageUsersForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsersForm);

const ws = withStyles(styles)(connectedManageUsersForm);

export default reduxForm({
  form: "editUser",
  validate
})(ws);
