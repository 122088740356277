import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

import { userActions } from "_actions";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classicModal: false
    };
  }

  handleLogout = () => {
    this.props.dispatch(userActions.logout());
    window.location.reload(true);
  };

  render() {
    const { classes, rtlActive } = this.props;

    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });

    return (
      <div className={wrapper}>
        <Button color="info" simple onClick={this.handleLogout}>
          <i
            className={
              classes.socialButtonsIcons +
              " " +
              classes.marginRight +
              " fas fa-sign-out-alt"
            }
          />
          {"  "}
          Logout
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const ws = withStyles(headerLinksStyle)(HeaderLinks);

const connectedHeaderLinks = connect(mapStateToProps)(ws);

export default connectedHeaderLinks;
