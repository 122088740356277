import React from "react";
import { organizationName } from "common/globalConstants";

class Terms extends React.Component {
  render() {
    return (
      <div>
        <p align="center">
          <strong>{organizationName}</strong>
        </p>
        <p align="center">
          <strong>Terms and Conditions</strong>
        </p>
        <center>
          <p>TOC should come here...</p>
        </center>
      </div>
    );
  }
}

export default Terms;
